<template>
    <div>
      <note-type-list></note-type-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  